export const environment = {
	production: false,
	dev: true,
	consoleLogAnalytics: true,
	isEmea: true,
	apiUrl: "https://dealer-emea-api-dev.control4.com/",
	secondaryApiUrl: "https://dealer-api-dev.control4.com/",
	secondaryPortalUrl: "https://dealer-dev.control4.com",
	technicianPortalUrl:
		"https://fullsandb-c4technician.cs96.force.com/technician/s/",
	postForwarderUrl:
		"https://www-dev.control4.com/assets/scripts/post-forwarder.php",
	trackingCode: "UA-69905-1",
};
